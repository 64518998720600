import * as R from 'ramda';

const transformNumber = (value) => {
    return Number(value?.replaceAll('.', '').replace(',', '.'));
}

const incrementarMes = (mouthYear) => {
    const [mes, ano] = mouthYear.split('-');
    const nextMonth = parseInt(mes, 10) === 12 ? 1 : parseInt(mes, 10) + 1;
    const nextYear = parseInt(mes, 10) === 12 ? parseInt(ano, 10) + 1 : parseInt(ano, 10);
    return `${nextMonth.toString().padStart(2, '0')}-${nextYear}`;
};

export function formatDataSave(dataFuncoes, dataCustos, projeto, mesAno) {
    let dados = {
        funcoes: [],
        custos: []
    }

    dataFuncoes.forEach((funcao) => {
        for (let indice in funcao.horas) {
            const novoObj = {
                nome_funcao: funcao.nomeFuncao,
                custo_venda_funcao: transformNumber(funcao.taxaCustoVenda),
                custo_total_funcao: funcao.totalFuncaoMes[indice],
                indice: Number(indice),
                horas_funcao: transformNumber(funcao.horas[indice]),
                projeto: projeto,
                codigo_funcao: funcao.tipoFuncao,
                mes_ano: /^\d{2}-\d{4}$/.test(mesAno[indice]) ? mesAno[indice] : null
            }

            dados?.funcoes.push(novoObj)
        }
    })

    dataCustos.forEach((custo) => {
        for (let indice in custo.valor) {
            const novoObj = {
                indice: Number(indice),
                tipo: custo.tipoCusto,
                descricao_custo_indireto: custo.descricao,
                valor_custo_indireto: transformNumber(custo.valor[indice]),
                projeto: projeto,
                mes_ano: /^\d{2}-\d{4}$/.test(mesAno[indice]) ? mesAno[indice] : null
            }

            dados?.custos.push(novoObj)
        }
    })

    return dados
}

export function getTotais(dataFuncoes, dataCustos, margem) {
    let totais = {
        totalHoras: 0,
        totalCustoVenda: 0,
        totalFuncao: 0,
        totalCustoIndireto: 0,
        total: 0,
        margemCusto: transformNumber(margem)
    }

    dataFuncoes.forEach((funcao) => {
        for (let mes in funcao.horas) {
            if (!isNaN(transformNumber(funcao.horas[mes])) && !isNaN(funcao.totalFuncaoMes[mes])) {
                totais.totalHoras = R.sum([totais.totalHoras, transformNumber(funcao.horas[mes])])
                totais.totalFuncao = R.sum([totais.totalFuncao, funcao.totalFuncaoMes[mes]])
            }
        }

        totais.totalCustoVenda = R.sum([totais.totalCustoVenda, transformNumber(funcao.taxaCustoVenda)])
    })

    dataCustos.forEach((custo) => {
        for (let mes in custo.valor) {
            if (!isNaN(transformNumber(custo.valor[mes]))) {
                totais.totalCustoIndireto = R.sum([totais.totalCustoIndireto, transformNumber(custo.valor[mes])])
            }
        }
    })

    totais.total = R.sum([totais.totalFuncao, totais.totalCustoIndireto])

    return totais;
}

export function formatResponse(data) {
    let funcoes = [];
    let custos = [];
    let indices = ['1'];
    let mesesAnos = {}

    data?.funcoes?.forEach((funcao) => {
        let existingItemIndex = funcoes.findIndex((f) =>
            (f.nomeFuncao === funcao.nome_funcao) &&
            (f.taxaCustoVenda.replace('.', ',') === funcao.custo_venda_funcao.replace('.', ',')) &&
            (f.tipoFuncao === funcao.codigo_funcao)
        );

        if (existingItemIndex === -1) {
            funcoes.push({
                id: Math.random().toString(36).substring(2, 9),
                nomeFuncao: funcao.nome_funcao,
                tipoFuncao: funcao.codigo_funcao,
                taxaCustoVenda: funcao.custo_venda_funcao.replace('.', ','),
                horas: {
                    [funcao.indice]: funcao.horas_funcao.replace('.', ',')
                },
                totalFuncaoMes: {
                    [funcao.indice]: funcao.custo_total_funcao
                }
            });

        } else {
            let existingMonthIndex = Object.keys(funcoes[existingItemIndex].horas).findIndex((month) => month === funcao.indice);

            if (existingMonthIndex === -1) {
                funcoes[existingItemIndex].horas[funcao.indice] = funcao.horas_funcao.replace('.', ',');
                funcoes[existingItemIndex].totalFuncaoMes[funcao.indice] = funcao.custo_total_funcao;
            } else {
                funcoes.push({
                    id: Math.random().toString(36).substring(2, 9),
                    nomeFuncao: funcao.nome_funcao,
                    tipoFuncao: funcao.codigo_funcao,
                    taxaCustoVenda: funcao.custo_venda_funcao.replace('.', ','),
                    horas: {
                        [funcao.indice]: funcao.horas_funcao.replace('.', ',')
                    },
                    totalFuncaoMes: {
                        [funcao.indice]: funcao.custo_total_funcao
                    }
                });
            }
        }

        if (!indices.includes(funcao.indice)) {
            indices.push(funcao.indice);
        }

        if (!mesesAnos[funcao.indice] && funcao.mes_ano) {
            mesesAnos[funcao.indice] = funcao.mes_ano;
        }
    });

    data?.custos?.forEach((custo) => {
        let existingItemIndex = custos.findIndex((c) => c.descricao === custo.descricao_custo_indireto);

        if (existingItemIndex === -1) {
            custos.push({
                id: Math.random().toString(36).substring(2, 9),
                tipoCusto: custo.tipo,
                descricao: custo.descricao_custo_indireto,
                valor: {
                    [custo.indice]: custo.valor_custo_indireto.replace('.', ',')
                }
            });
        } else {
            let existingMonthIndex = Object.keys(custos[existingItemIndex].valor).findIndex((month) => month === custo.indice);

            if (existingMonthIndex === -1) {
                custos[existingItemIndex].valor[custo.indice] = custo.valor_custo_indireto.replace('.', ',');
            } else {
                custos.push({
                    id: Math.random().toString(36).substring(2, 9),
                    tipoCusto: custo.tipo,
                    descricao: custo.descricao_custo_indireto,
                    valor: {
                        [custo.indice]: custo.valor_custo_indireto.replace('.', ',')
                    }
                });
            }
        }

        if (!indices.includes(custo.indice)) {
            indices.push(custo.indice);
        }

        if (!mesesAnos[custo.indice] && custo.mes_ano) {
            mesesAnos[custo.indice] = custo.mes_ano;
        }
    });


    indices.map(Number).sort((a, b) => a - b)

    // adicionar indices que faltam
/*     for (let i = indices[0]; i <= indices[indices.length - 1]; i++) {
        if (!indices.includes(i.toString())) {
            indices.push(i.toString());

            if (mesesAnos[i - 1]) {
                mesesAnos[i] = incrementarMes(mesesAnos[i - 1]);
            }
        }
    }
 */

    const getCompleteArray = (arr) => {
        const numberArray = arr.map(Number);
    
        const minValue = Math.min(...numberArray);
        const maxValue = Math.max(...numberArray);
    
        const completeArray = [];
        for (let i = minValue; i <= maxValue; i++) {
            completeArray.push(i.toString());
        }
    
        return completeArray;
    };

    const fillMissingDates = (obj) => {
        const indices = Object.keys(obj).map(Number);
    
        const minIndex = Math.min(...indices);
        const maxIndex = Math.max(...indices);
    
        const addMonths = (dateStr, months) => {
            const [month, year] = dateStr.split('-').map(Number);
            const date = new Date(year, month - 1);
            date.setMonth(date.getMonth() + months);
            const newMonth = (date.getMonth() + 1).toString().padStart(2, '0');
            const newYear = date.getFullYear();

            return `${newMonth}-${newYear}`;
        };
    
        const completeData = {};

        for (let i = minIndex; i <= maxIndex; i++) {
            if (obj[i]) {
                completeData[i] = obj[i];
            } else {
                const previousIndex = i - 1;
                completeData[i] = addMonths(completeData[previousIndex], 1);
            }
        }
    
        return completeData;
    };

    const indiceComplete = getCompleteArray(indices);
    const completeData = fillMissingDates(mesesAnos);

    mesesAnos = completeData

    indices.sort((a, b) => Number(a) - Number(b));
    indiceComplete.sort((a, b) => Number(a) - Number(b));

    return {
        funcoes,
        custos,
        indices,
        mesesAnos,
        indiceComplete
    };
}

export function getTipoCusto(id) {
    const tipos = {
        INFRA_CLOUD: 'Infraestrutura em Cloud',
        OUTROS_SOFTWARES: 'Outros softwares',
        HORAS_EXTRAS: 'Horas extras',
        FORNECEDORES_TERCEIROS: 'Fornecedores terceiros',
        OUTROS: 'Outros',
    };

    return tipos[id];
}
