import api from "./api";

function getAllPlans(dataInicial, dataFinal) {
    return api.get(`/planejado/plans/${dataInicial}/${dataFinal}`);
}

function getPlansByUserAndDate(params){
    const [accountId, dataInicial, dataFinal] = params;
    return api.get(`/relatorio-capacidade/user-plans/${accountId}/${dataInicial}/${dataFinal}`)
}

function getUserScheduleByUserAndDate(params) {
    const [accountId, dataInicial, dataFinal] = params;
    return api.get(`/relatorio-capacidade/user-schedule/${accountId}/${dataInicial}/${dataFinal}`)
}

function getUserWorklogByUserAndDate(params){
    const [accountId, dataInicial, dataFinal] = params;
    return api.get(`/relatorio-capacidade/user-worklog/${accountId}/${dataInicial}/${dataFinal}`)
}

function syncAllUsersConvertToJira(){
    return api.get(`/relatorio-capacidade/jira-users/`)
}

const PlanejamentoApiService = { getAllPlans, getPlansByUserAndDate, getUserScheduleByUserAndDate, getUserWorklogByUserAndDate, syncAllUsersConvertToJira }

export default PlanejamentoApiService