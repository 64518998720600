import React, { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import TableCustoExistente from "./TableCustoExistente";
import { FaPen, FaSave, FaTrash } from "react-icons/fa";
import { MdClose } from "react-icons/md";

function CustoDetail({
    item,
    handleCustos,
    onCancel,
    currentTab,
    rowsDataCustos,
    deleteValorCusto,
    edit
}) {
    const [formData, setFormData] = useState({ tipoCusto: "", descricao: "", valor: {} })
    const [editForm, setEditForm] = useState(false)
    const [errors, setErrors] = useState({})
    const [showExistentes, setShowExistentes] = useState(false)

    const dataNotThisTab = rowsDataCustos.filter(item => !item.valor.hasOwnProperty(currentTab))

    const mask = (value) => {
        value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

        const options = { minimumFractionDigits: 2 }

        const result = new Intl.NumberFormat('pt-BR', options).format(
            parseFloat(value) / 100
        )

        return result
    }

    const handleSave = () => {
        const newErrors = {}

        if (!formData.tipoCusto) newErrors.tipoCusto = "Campo obrigatório"

        if (!formData.descricao) newErrors.descricao = "Campo obrigatório"

        if (!formData.valor[currentTab] || Number(formData.valor[currentTab]) <= 0) {
            newErrors.valor = "Campo obrigatório"
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            return;
        }

        handleCustos(formData);
    }

    const onChangeData = (event) => {
        if (event.target.name === "valor") {
            const inputValue = mask(event.target.value)

            setFormData({ ...formData, valor: { ...formData.valor, [currentTab]: inputValue } })
            setErrors({})
            return;
        }

        setFormData({ ...formData, [event.target.name]: event.target.value })

        setErrors({})
    }

    const changeCustoExistente = (item) => {
        setFormData(item)
        setShowExistentes(false)
    }

    const onStart = () => {
        const formDataItem = item || { tipoCusto: "", descricao: "", valor: {} }

        setFormData(formDataItem);
        setEditForm(!!item)
    }

    useEffect(onStart, [item])

    return (
        <Form>
            { !showExistentes ? (
                <>
                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control
                            as="select"
                            name="tipoCusto"
                            value={formData.tipoCusto}
                            onChange={onChangeData}
                            disabled={editForm}
                            isInvalid={!!errors.tipoCusto}
                            >
                                <option value="">Selecione</option>
                                <option value="INFRA_CLOUD">Infraestrutura em Cloud</option>
                                <option value="OUTROS_SOFTWARES">Outros softwares</option>
                                <option value="HORAS_EXTRAS">Horas extras</option>
                                <option value="FORNECEDORES_TERCEIROS">Fornecedores terceiros</option>
                                <option value="OUTROS">Outros</option>
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                {errors?.tipoCusto}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                type="text"
                                name="descricao"
                                value={formData.descricao}
                                onChange={onChangeData}
                                disabled={editForm}
                                isInvalid={!!errors.descricao}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.descricao}
                            </Form.Control.Feedback>
                        </Col>

                        <Col>
                            <Form.Label>Valor</Form.Label>
                            <Form.Control
                                type="text"
                                name="valor"
                                value={formData.valor[currentTab] || '0,00'}
                                onChange={onChangeData}
                                disabled={editForm}
                                isInvalid={!!errors.valor}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors?.valor}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                </>
            ) : (
                <TableCustoExistente
                    dataNotThisTab={dataNotThisTab}
                    changeCustoExistente={changeCustoExistente}
                />
            )}
            { edit ?
                item ? (
                    <div className="mt-3 d-flex justify-content-end">
                        { editForm ? (
                            <ButtonGroup>
                                <Button variant="outline-info" onClick={() => setEditForm(false)} disabled={!edit}>
                                    <FaPen /> Editar
                                </Button>
                                <Button variant="outline-danger" onClick={() => deleteValorCusto(item?.id)} disabled={!edit}>
                                    <FaTrash /> Excluir
                                </Button>
                            </ButtonGroup>
                        ) : (
                            <ButtonGroup>
                                <Button variant="outline-success" onClick={handleSave}>
                                    <FaSave /> Salvar
                                </Button>
                                <Button variant="outline-danger" onClick={() => setEditForm(true)}>
                                    <MdClose /> Cancelar
                                </Button>
                            </ButtonGroup>
                        )}
                    </div>
                ) : (
                    <div className={`mt-3 d-flex justify-content-${dataNotThisTab.length <= 0 ? 'end' : 'between'}`}>
                        { dataNotThisTab.length > 0 && (
                            <Button variant="outline-info" onClick={() => setShowExistentes(!showExistentes)}>
                                { showExistentes ? "Voltar" : "Adicionar um de outro mês" }
                            </Button>
                        )}

                        { !showExistentes && (
                            <ButtonGroup>
                                <Button variant="outline-success" onClick={handleSave}>Salvar</Button>
                                <Button variant="outline-danger" onClick={onCancel}>Cancelar</Button>
                            </ButtonGroup>
                        )}
                    </div>
                )
                : null
            }
        </Form>
    )
}

export default CustoDetail;
