import React, { useEffect, useState } from "react";
import ListLayout from "../../../../components/ListLayout";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import * as R from 'ramda'
import { costsColumns } from "../ProjetoPrevistoViewInfo";
import { getTipoCusto } from "../formatData";

const INITIAL_PAGEPARAMS = {
    orderBy: 'id',
    orderDir: 'asc'
};
const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function TableCustos({ currentTab, rowsDataCustos, openModalCosts, edit }) {
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);

    const transformNumber = (value) => {
        return Number(value?.replaceAll('.', '').replace(',', '.'));
    }

    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }

        const columnsHeader = costsColumns;

        columnsHeader.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            const head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            headers.push(head);
        })
        return headers;
    }

    const getCostsRows = () => {
        let rows = rowsDataCustos.filter(item => item.valor[currentTab] !== undefined)
            .map(item => {
                const row = {
                    onClick: () => openModalCosts(item),
                    data: costsColumns.map(col => {
                        if (col.name === 'valor') {
                            return {
                                value: transformNumber(item[col.name][currentTab])
                                    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                            }
                        }

                        if (col.name === 'tipoCusto') {
                            return { value: getTipoCusto(item[col.name]) };
                        }

                        return { value: item[col.name] };
                    })
                }
                return row;
            })

        const classNameTotal = ['font-weight-bold'];

        if (rows.length > 0) {
            const total = rows.reduce((totalItens, item) => {
                totalItens = R.sum([totalItens, Number(item.data[2].value.replace('R$', '').replaceAll('.', '').replace(',', '.'))]);

                return totalItens;
            }, 0)

            rows.push({
                data: [
                    { value: 'Total', className: classNameTotal },
                    { value: '', className: classNameTotal },
                    { value: total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), className: classNameTotal },
                ]
            })
        }

        return rows;
    }

    useEffect(() => getCostsRows, [rowsDataCustos, currentTab])

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <h4>Custos Indiretos</h4>

                { edit &&
                    <Button onClick={() => openModalCosts()} disabled={!edit}><FaPlus /></Button>
                }
            </div>

            <ListLayout
                columns={getListHeader()}
                rows={getCostsRows()}
            />
        </>
    )
}

export default TableCustos;
