/**
 * Array contendo as opções de menu de relatórios a serem disponibilizados
 */
export const menuRelatorios = [
    {
        groupLabel: "Relatórios Financeiros",
        groupKey: "relatoriosFinanceiros",
        menus: [
            {
                label: "Comparativo de Custo Planejado vs Real",
                path: "realPlanejadoCusto",
                info: "Exibe uma tabela acompanhada de um gráfico que compara o custo planejado do projeto com o custo real ao longo do tempo."
            },
            {
                label: "Histórico de Custo por Cliente / Projeto",
                path: "historicoCustoClienteProjeto",
                info: "Exibe o histórico dos custos associados a cada cliente e projeto."
            },
            {
                label: "Comparativo de Faturamento Planejado vs Real",
                path: "realPlanejadoFaturamento",
                info: "Apresenta uma tabela e um gráfico que comparam o faturamento previsto com o faturamento real do projeto ao longo do tempo."
            },
            {
                label: "Resultado do Projeto",
                path: "resultadoAnualProjeto",
                info: "Fornece uma visão da saúde financeira do projeto considerando o faturamento, o custo e a margem."
            }
        ]
    },
    // {
    //     groupLabel: "Relatórios de Planejamento",
    //     groupKey: "relatoriosPlanejamento",
    //     menus: [
    //         {
    //             label: "Capacidade da Área",
    //             path: "relatorioCapacidade",
    //             info: "Taxa de Capacidade da Área"
    //         },
    //         {
    //             label: "Capacidade por Projeto / Cliente / Líder",
    //             path: "capacidadeClienteProjetoLider",
    //             info: ""
    //         },
    //     ]
    // }
];

/**
 * Filtra o menu de relatórios conforme o termo recebido
 * @param {string} termo a ser buscado no array de menu
 * @returns {Array<any>} array de opções filtrado pelo termo
 */
export const applyFiltroMenu = (termo = "") => {
    if (!termo.length) return menuRelatorios;
    
    const filtrados = [];
    const lowerTermo = termo.toLowerCase();

    menuRelatorios.forEach((group => {
        const menus = group.menus.filter(item => {
            const lowerLabel = item.label.toLowerCase();
            return lowerLabel.includes(lowerTermo);
        })

        if (menus.length) {
            filtrados.push({...group, menus});
        }
    }));

    return filtrados;
} 
